<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 18:03:02
 * @LastEditTime: 2022-07-04 10:59:45
-->
<template>
	<el-menu mode="vertical" class="sidebar-el-menu" :default-active="onRoutes" :collapse="collapse"
		background-color="#1C2233" text-color="#ffffff" unique-opened router>
		<template v-for="itemF in menus">
			<!-- 有二级菜单 -->
			<template v-if="itemF.children && itemF.children.length > 0">
				<!-- 一级菜单 -->
				<el-submenu :index="itemF.path" :key="itemF.path">
					<!--  一级标题 -->
					<template #title>
						<i :class="'iconfont' + ' ' + itemF.meta.icon"></i>
						<span>{{ itemF.meta.title }}</span>
					</template>
					<!-- 二级菜单 -->
					<template v-for="itemS in itemF.children">
						<!-- 有三级菜单 -->
						<el-submenu v-if="itemS.children" :index="itemS.path" :key="itemS.path">
							<!-- 二级标题 -->
							<template #title>{{ itemS.meta.title }}</template>
							<!-- 三级菜单 -->
							<el-menu-item v-for="itemT in itemS.children" :key="itemT.path" :index="itemT.path">
								{{ itemT.meta.title }}
							</el-menu-item>
						</el-submenu>
						<!-- 二级菜单 -->
						<el-menu-item v-if="!itemS.children&&!itemS.hidden" :index="itemS.path" :key="itemS.path">
							<span class="sidebar-point">·</span>
							{{itemS.meta.title}}
						</el-menu-item>
					</template>
				</el-submenu>
			</template>
			<!-- 只有一级菜单 -->
			<template v-else>
				<el-menu-item :index="itemF.path" :key="itemF.path">
					<i :class="itemF.meta.icon"></i>
					<template #title>{{ itemF.meta.title }}</template>
				</el-menu-item>
			</template>
		</template>
	</el-menu>
</template>
<script>
	import storage from 'store';
	import * as asyncRouters from "@/router/asyncRouter";
	export default {
		props: {
			collapse: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				menus: asyncRouters.default[0].children
			};
		},
		methods: {},
		computed: {
			onRoutes() {
				// storage.set("routerArr",[])
				this.handleRoute(this.$route)
				console.log("路由", this.$route);
				storage.set("historyRoute", this.$route.fullPath)
				this.$emit("changeBread", this.$route);
				return this.$route.path;
			}
			// collapse() {
			//   return this.$store.state.collapse;
			// },
		},
		created() {},
		methods:{
			handleRoute(params){
				console.log(params,'params')
				var routeArr = []
				var item = {
					fullPath:params.fullPath,
					path:params.path,
					title:params.meta.title,
					checked:true
				}
				if(sessionStorage.getItem("routerArr")){
					routeArr = JSON.parse(sessionStorage.getItem("routerArr"))
					routeArr.forEach(el=>{
						el.checked = false
					})
					if(routeArr&&routeArr.length==0){
						routeArr.push(item)
					}else{
						const index=routeArr.findIndex((item)=>{
							return item.path===params.path
						})
						console.log(index,'index')
						if(index===-1){
							routeArr.push(item)
						}else{
							routeArr.splice(index,1,item)
						}
					}
					sessionStorage.setItem("routerArr",JSON.stringify(routeArr))
				}else{
					routeArr.push(item)
					sessionStorage.setItem("routerArr",JSON.stringify(routeArr))
				}
			}
		}
	};
</script>
<style lang="scss" scoped>
	.iconfont {
		color: #fff;
		font-size: 14px;
		margin-right: 8px;
	}

	.sidebar::-webkit-scrollbar {
		width: 0;
	}

	.sidebar-icon {
		width: 16px;
		height: 16px;
		margin-right: 8px;
	}

	.sidebar-point {
		font-size: 20px;
		line-height: 20px;
		display: inline-block;
		margin-bottom: 5px;
	}
</style>
