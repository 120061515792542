<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-09 18:23:56
 * @LastEditTime: 2021-06-09 18:40:47
-->
<template>
	<div class="breadcrumb">
		<el-tag
		class="tag-box"
		v-for="(tag,index) in breadcrumbArr"
		:key="tag.path"
		@close="handleClose(tag,index)"
		:type="tag.checked==false?'info':'success'"
		@click="handleTag(tag,index)"
		closable>
		{{tag.title}}
		</el-tag>
		
		<!-- <el-breadcrumb separator="/">
			<el-breadcrumb-item v-for="(item, index) in breadcrumbArr" :key="index" :to="index == 0 ?item.path:''">
				{{item.title}}</el-breadcrumb-item>
		</el-breadcrumb> -->
	</div>
</template>
<script>
	import storage from 'store';
	export default {
		props: {
			breadcrumbArr: {
				type: Array,
				default: undefined,
			},
		},
		data(){
			return{
				editableTabsValue:0
			}
		},
		created() {
			console.log('9996', this.breadcrumbArr)
		},
		methods:{
			handleClose(tag,index){
				var routeArr =  JSON.parse(sessionStorage.getItem("routerArr"))
				routeArr.splice(index,1)
				console.log(index,routeArr.length)
				if(routeArr.length>0){
					if(index===routeArr.length||index>0){
						routeArr[routeArr.length-1].checked = true
						var fullPath = routeArr[routeArr.length-1].fullPath
						this.$router.replace(fullPath)
					}
				}else{
					this.$router.replace('/recruitList')
				}
				sessionStorage.setItem("routerArr",JSON.stringify(routeArr))
				this.$emit('changeBreadCrumbArr',routeArr)
				console.log(routeArr,'routeArr')
			},
			//点击切换tag
			handleTag(tab,index){
				var routeArr =  JSON.parse(sessionStorage.getItem("routerArr"))
				if(routeArr.length>0){
					this.$router.replace(routeArr[index].fullPath)
					routeArr[index].checked = true
				}
			},
		}
	};
</script>
<style lang="scss" scoped>
	.breadcrumb {
		// margin-bottom: 10px;
		// background:#ffffff;
	}
	.tag-box{
		margin-right:4px;
		margin-bottom:10px;
	}
	/deep/.el-tag.el-tag--info{
		cursor: pointer;
		background:#ffffff;
	}
	/deep/.el-tag--success{
		color:#1CABB9;
		border-color:#1CABB9;
		background-color:#ffffff;

	}
	/deep/.el-tag.el-tag--success .el-tag__close:hover{
		background-color:#1CABB9;
	}
	// .tag{
	// 	/deep/.el-tag.el-tag--info{
	// 		border-color:#1CABB9;
	// 		color:#1CABB9;
	// 	}
	// }
	
</style>
