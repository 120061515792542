<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 16:22:17
 * @LastEditTime: 2021-06-29 16:52:27
-->
<template>
	<div class="layout">
		<el-container>
			<!-- 顶部导航栏 -->
			<el-header>
				<Header />
			</el-header>
			<el-container>
				<!-- 导航栏 -->
				<Slider :collapse="collapse" @changeBread="changeBread" />
				<!-- 内容区域 -->
				<el-main>
					<Breadcrumb :breadcrumbArr="breadcrumbArr" @changeBreadCrumbArr="changeBreadCrumbArr"/>
					<Main @close="close" :breadcrumbArr="breadcrumbArr" />
				</el-main>
			</el-container>
		</el-container>
	</div>
</template>
<script>
	import Header from "./components/headers";
	import Slider from "./components/slider";
	import Main from "./components/main";
	import Breadcrumb from "./components/breadcrumb";
	import storage from 'store';
	import {
		mapState
	} from 'vuex'
	export default {
		name: "LayOut",
		components: {
			Header,
			Slider,
			Main,
			Breadcrumb,
		},
		data() {
			return {
				collapse: false,
				breadcrumbArr: [],
				propsRoute:[]
			};
		},
		computed: {
			...mapState({
				// 动态主路由
				mainMenu: (state) => state.permission.addRouters,
			}),
		},
		methods: {
			close() {
				this.collapse = !this.collapse;
			},
			changeBread(props) {
				console.log(props,'props')
				this.breadcrumbArr =  JSON.parse(sessionStorage.getItem("routerArr"))
				// let propsRoute = []
				// if (props.meta) {
				// 	propsRoute.push(props.meta)
				// 	if (props.meta.parentRoute) {
				// 		propsRoute = propsRoute.concat(props.meta.parentRoute)
				// 	}
				// }
				// this.breadcrumbArr = propsRoute.reverse()
				// console.log(this.breadcrumbArr,'this.breadcrumbArr')
			},
			changeBreadCrumbArr(arrRouter){
				console.log(arrRouter,'arrRouter111111')
				this.breadcrumbArr = arrRouter
			},
		},
		mounted() {
			console.log("当前breadcrumbArr233是>>>>", this.breadcrumbArr);
			console.log('mainMenus', this.mainMenu)
			let that = this
			window.onresize = () => {
				return (() => {
					window.screenWidth = document.body.clientWidth;
					// console.log("屏幕宽度", window.screenWidth);
					if(window.screenWidth*1 < 1280) {
						this.collapse = true
					} else {
						this.collapse = false
					}
				})()
			}
		},
	};
</script>
<style lang="scss" scoped>
</style>
