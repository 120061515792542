<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 16:22:17
 * @LastEditTime: 2022-08-05 16:14:04
-->
<template>
	<div class="header_box">
		<div @click="toIndex" class="header_left">
			<!-- 顶部LOGO -->
			<div class="header_logo">
				<img src="@/assets/images/layout/logo.png" alt="" />
			</div>
			<div class="header_title">
				<img src="../../../assets/images/layout/name.png">
			</div>
		</div>
		<div class="header_right">
			<el-dropdown trigger="click">
				<div class="header_userinfo">
					<div class="">
						<span class="header_userinfo_name">
							{{userInfo.userName}}
						</span>
					</div>
					<div class="header_avator">
						<img v-if="userInfo.avatarUrl" :src="userInfo.avatarUrl" alt="" />
						<img v-else src="@/assets/images/common/avatar.png" alt="" />
					</div>
				</div>
				<el-dropdown-menu style="width: 160px;" slot="dropdown">
					<el-dropdown-item style="font-size: 14px;color: #303133;">
						<div @click="personalFn('views')">个人信息</div>
					</el-dropdown-item>
					<el-dropdown-item style="font-size: 14px;color: #303133;">
						<div @click="personalFn('pwd')">修改密码</div>
					</el-dropdown-item>
					<el-dropdown-item style="font-size: 14px;color: #909399;border-top: 1px solid #DFE6F0;">
						<div @click="loginOut">退出登录</div>
					</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
		<!-- 个人信息 -->
		<el-dialog @close="closePersonalDialog" title="提示" :modal="false" class="dialog-bg" :show-close="false"
			:visible.sync="dialogPersonalVisible" width="820px">
			<div slot="title" class="dialog-header">
				<span>个人信息</span>
				<img @click="closePersonalDialog" src="@/assets/images/common/close.png">
			</div>
			<div class="dialog-content">
				<div class="dialog-content-avatar">
					<img v-if="userInfo.avatarUrl" :src="userInfo.avatarUrl" alt="" />
					<img v-else src="@/assets/images/common/avatar.png">
				</div>
				<div class="dialog-content-name">{{userInfo.realname}}</div>
				<div class="dialog-content-row flex-content" style="margin-bottom: 16px;">
					<span class="dialog-content-label">所属组织：</span>
					<span class="dialog-content--val">{{userInfo.orgShortName}}</span>
				</div>
				<div class="dialog-content-row">
					<span class="dialog-content-label">拥有权限：</span>
					<div class="dialog-content-tags">
						<span v-for="(item, index) in userInfo.roleList" :key="index">{{item.permissionName}}</span>
					</div>
				</div>
			</div>
		</el-dialog>
		<el-dialog @close="closePasswordDialog" title="提示" :modal="false" class="dialog-bg" :show-close="false"
			:visible.sync="dialogPasswordVisible" width="520px">
			<div slot="title" class="dialog-header">
				<span>修改密码</span>
				<img @click="closePasswordDialog" src="@/assets/images/common/close.png">
			</div>
			<div class="password-content">
				<el-form :model="passwordForm" :rules="passwordRules" ref="passwordForm" label-width="100px" class="">
					<el-form-item label="旧密码:" prop="oldPassword">
						<div class="password-dia-ipt">
							<el-input :type="!showOldPwd? 'password':'text'" v-model="passwordForm.oldPassword"
								placeholder="请输入旧密码"></el-input>
							<img @click="showPwdFn('old')" v-if="!showOldPwd"
								src="@/assets/images/common/eye_close.png">
							<img @click="showPwdFn('old')" v-else src="@/assets/images/common/eye_open.png">
						</div>
					</el-form-item>
					<el-form-item label="新密码:" prop="password">
						<div class="password-dia-ipt">
							<el-input :type="!showNewPwd? 'password':'text'" v-model="passwordForm.password"
								placeholder="请输入6-12位数字字母组合新密码"></el-input>
							<img @click="showPwdFn('new')" v-if="!showNewPwd"
								src="@/assets/images/common/eye_close.png">
							<img @click="showPwdFn('new')" v-else src="@/assets/images/common/eye_open.png">
						</div>
					</el-form-item>
					<el-form-item label="确认密码:" prop="confirmPassword">
						<div class="password-dia-ipt">
							<el-input :type="!showRePwd? 'password':'text'" v-model="passwordForm.confirmPassword"
								placeholder="请再次输入6-12位数字字母组合新密码"></el-input>
							<img @click="showPwdFn('re')" v-if="!showRePwd" src="@/assets/images/common/eye_close.png">
							<img @click="showPwdFn('re')" v-else src="@/assets/images/common/eye_open.png">
						</div>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="closePasswordDialog">取 消</el-button>
				<el-button @click="submitPasswordDialog" type="primary">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import storage from 'store';
	import {
		changePassword
	} from "@/api/system";
	export default {
		data() {
			const checkPwd = (rule, value, callback) => {
				if (!value) {
					callback(new Error("请输入6-12位数字字母组合新密码"));
				} else {
					let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/;
					if (!reg.test(value) || value.length < 6 || value.length > 12) {
						callback(new Error("请输入6-12位数字字母组合新密码"));
					} else {
						callback();
					}
				}
				callback();
			};
			const checkConfirmPwd = (rule, value, callback) => {
				if (!value) {
					callback(new Error("请再次输入6-12位数字字母组合新密码"));
				} else {
					let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/;
					if (!reg.test(value) || value.length < 6 || value.length > 12) {
						callback(new Error("请输入6-12位数字字母组合新密码"));
					} else {
						if (this.passwordForm.confirmPassword != this.passwordForm.password) {
							callback(new Error("输入的新密码和确认密码密码不一致"));
						} else {
							callback();
						}
					}
				}
				callback();
			};
			return {
				dialogPersonalVisible: false,
				dialogPasswordVisible: false,
				permissionList: [{
						name: "招募管理"
					},
					{
						name: "志愿者评级"
					},
				],
				passwordRules: {
					oldPassword: [{
						required: true,
						message: "请输入旧密码",
						trigger: "blur"
					}],
					password: [{
						required: true,
						validator: checkPwd,
						trigger: "blur"
					}],
					confirmPassword: [{
						required: true,
						validator: checkConfirmPwd,
						trigger: "blur"
					}],
				},
				passwordForm: {},
				showOldPwd: false,
				showNewPwd: false,
				showRePwd: false,
				userInfo: {},
			};
		},
		watch: {
			dialogPasswordVisible: {
				handler(val) {
					if (!val) {
						this.$nextTick(() => {
							this.$refs["passwordForm"].clearValidate()
						})
					}
				},
				deep: true
			}
		},
		created() {},
		mounted() {
			this.getUserInfo()
		},
		methods: {
			getUserInfo() {
				let localInfo = storage.get('ACCESS_Info')
				let localPermission = storage.get('ACCESS_ROLES').permissions
				this.userInfo = {
					userName: storage.get('ACCESS_Name'),
					avatarUrl: localInfo.avatarUrl,
					realname: localInfo.realname,
					orgShortName: localInfo.orgShortName,
					roleList: localPermission,
				}
				// if (localInfo.orgId == -1 || localInfo.roleList[0].code == 'SUPER_ADMIN') {
				// 	this.userInfo.orgShortName = localInfo.roleList[0].name
				// } else {
				// 	this.userInfo.orgShortName = localInfo.orgShortName
				// }
			},
			closePersonalDialog() {
				this.dialogPersonalVisible = false
			},
			closePasswordDialog() {
				this.dialogPasswordVisible = false
			},
			submitPasswordDialog() {
				this.$refs["passwordForm"].validate(valid => {
					if (valid) {
						let params = JSON.parse(JSON.stringify(this.passwordForm))
						changePassword(params).then((res) => {
							if (res.code == 0) {
								this.$message.success("密码修改成功~")
								this.dialogPasswordVisible = false
							}
						})
					}
				})
			},
			personalFn(type) {
				switch (type) {
					case "views":
						this.dialogPersonalVisible = true
						break;
					case "pwd":
						this.dialogPasswordVisible = true
						break;
				}
			},
			loginOut() {
				console.log("loginOut")
				this.$store.dispatch("Logout").then(() => {
					setTimeout(() => {
						window.location.reload();
					}, 16);
				});
			},
			toIndex() {
				this.$router.push({
					path: "/recruitList"
				})
			},
			showPwdFn(type) {
				switch (type) {
					case "old":
						this.showOldPwd = !this.showOldPwd
						break;
					case "new":
						this.showNewPwd = !this.showNewPwd
						break;
					case "re":
						this.showRePwd = !this.showRePwd
						break;
				}
			}
		},
	};
</script>
<style lang="scss" scoped>
	/deep/.el-input__inner {
		width: 364px;
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 1px solid #edf1f7;
	}

	.flex-content {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.header_box {
		height: 64px;
		background: #222d3c;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.header_left {
			margin: 0 20px;
			display: flex;
			align-items: center;
			cursor: pointer;

			.header_logo {
				width: 40px;
				height: 40px;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.header_title {
				width: 216px;
				height: 24px;
				margin-left: 16px;

				img {
					width: 100%;
					height: 100%;
				}
			}
		}

		.header_right {
			.header_userinfo {
				display: flex;
				align-items: center;
				cursor: pointer;

				.header_avator {
					width: 33px;
					height: 33px;
					margin: 0 16px;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					border: 1px solid #ffffff;

					img {
						width: 32px;
						height: 32px;
						border-radius: 50%;
					}
				}

				.header_userinfo_name {
					color: #fff;
					font-size: 16px;
					font-family: PingFang SC;
					font-weight: bold;
					line-height: 22px;
					color: #ffffff;
				}
			}
		}

		.dialog-content {
			display: flex;
			flex-direction: column;
			padding: 24px 40px 40px 40px;

			.dialog-content-avatar {
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					width: 60px;
					height: 60px;
					border: 1px solid #ffffff;
					border-radius: 50%;
				}
			}

			.dialog-content-name {
				font-size: 16px;
				font-family: PingFang SC;
				font-weight: bold;
				line-height: 22px;
				color: #303133;
				margin: 8px 0;
				text-align: center;
			}

			.dialog-content-row {
				display: flex;
				align-items: flex-start;

				.dialog-content-label {
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 20px;
					color: #606266;
				}

				.dialog-content--val {
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 20px;
					color: #303133;
				}

				.dialog-content-tags {
					flex: 1;
					display: flex;
					align-items: center;
					flex-wrap: wrap;

					span {
						font-size: 12px;
						font-family: PingFang SC;
						font-weight: 400;
						line-height: 21px;
						color: #909399;
						text-align: center;
						padding: 0 8px;
						border: 1px solid #dcdfe6;
						border-radius: 2px;
						margin: 0 8px 10px 0;
					}
				}
			}
		}

		.password-content {
			margin-top: 20px;

			.password-dia-ipt {
				width: 364px;
				position: relative;

				img {
					position: absolute;
					right: 10px;
					top: 12px;
					width: 16px;
					height: 16px;
					cursor: pointer;
				}
			}
		}
	}
</style>
